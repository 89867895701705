<template>
  <div v-loading="fullLoading">
    <list-template
        ref="listDom"
        :loading="loading"
        :is-download="true"
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :table-config="tableConfigList"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        :events="{'selection-change': onSelectionChange}"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
        @onExportData="onDownload"
        @onLeaveJobs="handleVisible(1)"
        @onAutoAllocate="handleSelect"
        @onStartAllocate="handleVisible(2)"
        :isDisabled="{startAllocate: buttonIsDisable}"
        :isHide="{startAllocate: !isCanSelect}"
    ></list-template>
    <Dialog :visible.sync="dialogVisible" :title="title" size="small" :showClose="false" :showFooter="false" :closeOnClickModal="false">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140rem">
        <el-form-item label="已离职老师" prop="chg_teacher_from" v-if="dialogVisible1">
          <el-select
            v-model="formData.chg_teacher_from"
            filterable
            remote
            reserve-keyword
            placeholder="请搜索"
            :remote-method="(e) => handleRemote(e, 1)"
            :loading="remoteLoading1"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="转移交接人" prop="chg_teacher_to">
          <el-select
            v-model="formData.chg_teacher_to"
            filterable
            remote
            reserve-keyword
            placeholder="请搜索"
            :remote-method="(e) => handleRemote(e, 2)"
            :loading="remoteLoading2"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button :loading="dialogLoading" type="primary" @click="handleSubmit('form')">提交</el-button>
          <el-button :disabled="dialogLoading" @click="handleVisible(0)">取消</el-button>
        </el-form-item>
      </el-form>
    </Dialog>
    <Dialog :visible.sync="dialogVisible3" title="提示" size="small" :showClose="false" :showFooter="false" :closeOnClickModal="false">
      <div class="dialog-box flex-center" v-if="dialogLoading">
        <div>数据正在处理中 ...</div>
      </div>
      <div class="dialog-box flex flex-column justify-between" v-else>
        <div class="px-5 t-c">共有【{{info.count}}】个订单将{{ info.name1 ? `由【${info.name1}】` : '' }}交接给【{{info.name2}}】 是否继续操作？</div>
        <div class="flex-center">
          <el-button type="primary" @click="handleSure">开始分配</el-button>
          <el-button @click="handleVisible(3)">取消</el-button>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Dialog from "@/components/common/Dialog";
import LineWrapper from "@/components/common/LineWrapper";
import LineItem from "@/components/common/LineItem";
import tools from "@/utils/tools";
import { setConfigOption } from "@/utils";
import {get_grade_zs,get_recruit_teacher,get_recruit_count,edit_belong_teacher,zsGet} from "@/api";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: { Dialog, LineWrapper, LineItem },
  data() {
    return {
      loading: false,
      search: {},
      searchConfig: [
        {
          prop: "order_status",
          tag: 'select',
          placeholder: "筛选订单状态",
          options: [{ label: '待支付', value: 1 },{ label: '已支付', value: 2 },{ label: '已取消', value: 3 }]
        },
        {
          prop: "school_id",
          tag: 'select',
          placeholder: "筛选就读校区",
          label: 'school_name',
          value: 'id',
          options: [],
        },
        {
          prop: "grade_id",
          tag: 'select',
          placeholder: "筛选年级",
          options: [],
        },
        {
          prop: "student_status",
          tag: 'select',
          placeholder: "筛选学生状态",
          options: [],
        },
        {
          prop: "sign_status",
          tag: 'select',
          placeholder: "筛选签约状态",
          options: [
            { label: '待签约', value: 1 },
            { label: '已签约', value: 2 },
            { label: '-', value: 101 },
          ],
        },
        {
          prop: "time",
          tag: 'daterange',
          start: "创建订单时间",
          rangeText: '-'
        },
        {
          prop: "student_name",
          placeholder: "搜索学生姓名",
        },
        {
          prop: "customer_name",
          placeholder: "搜索家长姓名",
        },
        {
          prop: "belong_teacher_name",
          placeholder: "搜索招生老师",
        },
      ],

      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig: [
        {prop: "order_status", label: "订单状态"},
        {prop: "student_status_cn", label: "学生状态"},
        {prop: "student_name", label: "学生姓名"},
        {prop: "customer_name", label: "家长姓名"},
        {prop: "school", label: "实际就读学校"},
        {prop: "grade_name", label: "报名年级"},
        {prop: "belong_teacher_name", label: "客户归属人", render: row => `<span class="${row.teacher_status ? '' : 'text-gray'}">${row.belong_teacher_name}</span>`},
        {prop: "sign_status", label: "签约状态"},
        {prop: "created_at", label: "创建订单时间"},
        { prop: "handle", label: "操作", width: "140rem", handle: true }
      ],

      historyDialogShow: false,
      historyList: [],
      formData: { chg_teacher_from:'', chg_teacher_to: '' },
      rules: {
        chg_teacher_from: [{ required: true, message: '请输入', trigger: 'change' }],
        chg_teacher_to: [{ required: true, message: '请输入', trigger: 'change' }]
      },
      title: '',
      isCanSelect: false,
      fullLoading: false,
      dialogLoading: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      remoteLoading1: false,
      remoteLoading2: false,
      options1: [],
      options2: [],
      info: {},
      selectionData: [],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.getSchool()
    this.getConfig()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"]),
    tableConfigList() {
      const arr = this.tableConfig.map(i => ({...i}))
      if(this.isCanSelect) arr.unshift({type: "selection"})
      return arr
    },
    dialogVisible() {
      return this.dialogVisible1 || this.dialogVisible2
    },
    buttonIsDisable() {
      if(!this.isCanSelect || !this.selectionData.length) return true
      return false
    }
  },
  methods: {
    getConfig() {
      get_grade_zs().then(res => {
        setConfigOption({ list: this.searchConfig, data: res, key: 'grade_id', label: 'name', value: 'id' })
      })
      zsGet('/recruit/fresh-graduate/student-status').then(data => {
      const list = Object.keys(data).map(item => ({ label: data[item], value: Number(item), disabled: true }))
      setConfigOption({ list: this.searchConfig, data: list, key: 'student_status' })
      })
    },
    getData() {
      if(this.loading) return
      this.loading = true
      let params = {...JSON.parse(JSON.stringify(this.search)), page: this.page}
      if(params.time && params.time.length){
        params.start_created_at = params.time[0]
        params.end_created_at = params.time[1]
        delete params.time
      }
      this.tableData = [];
      this.$_register('api/recruit/fresh-graduate/index', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    // 校区数据获取
    getSchool(){
      this.$_register('api/recruit/common/consulting-school').then(res => {
        this.searchConfig[1].options = res.data.data.entity_school || []
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, text) {
      const num = Number(row.school_year)
      if (text.type === 'edit') {
        this.$router.push(`./edit${num > 2023 ? '2' : ''}?id=${row.id}`)
      }

      if (text.type === 'view') {
        this.$router.push(`./detail${num < 2024 ? 's' : ''}?id=${row.id}`)
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

    onDownload() {
      let params = {...JSON.parse(JSON.stringify(this.search)), page: this.page}
      if(params.time && params.time.length){
        params.start_created_at = params.time[0]
        params.end_created_at = params.time[1]
        delete params.time
      }
      this.$_register('api/recruit/fresh-graduate/export-v2', {params}).then(res => {
        tools.download('应届生报名', res.data)
      })
    },
    handleRemote(query, index) {
      if (query) {
        this[`remoteLoading${index}`] = true
        const data = { name: query }
        if(index === 2) data.status = 1
        get_recruit_teacher(data).then(res => {
          this[`options${index}`] = res.map((item) => {
            return { value: item.id, label: `${item.name}(${item.username})`, name: item.name }
          })
        }).finally(() => {
          this[`remoteLoading${index}`] = false
        })
      } else {
        this[`options${index}`] = []
      }
    },
    handleInit() {
      this.formData = { chg_teacher_from:'', chg_teacher_to: '' }
      this.info = {}
    },
    handleVisible(type) {
      if(!type) type = this.type
      if(type === 2 && !this.selectionData.length) {
        return this.$message({type: 'warning', message: '请选择数据'})
      }
      this[`dialogVisible${type}`] = !this[`dialogVisible${type}`]
      if(!this[`dialogVisible${type}`]) return this.handleInit()
      this.options1 = []
      this.options2 = []
      if(type === 1) this.title = '离职交接'
      else if(type === 2) this.title = '准备开始分配'
      this.type = type
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if(valid) {
          const info = {}
          info.name2 = this.options2.find(item => item.value === this.formData.chg_teacher_to).name
          if(this.type === 1) {
            info.name1 = this.options1.find(item => item.value === this.formData.chg_teacher_from).name
          } else if(this.type === 2) {
            info.count = this.selectionData.length
            this.info = info
            this[`dialogVisible${this.type}`] = false
            this.dialogVisible3 = true
            return
          }
          get_recruit_count({ belong_teacher_id: this.formData.chg_teacher_from }).then(res => {
            const { order_num } = res
            if(order_num === 0) {
              return this.$message({type: 'warning', message: '该老师没有报名数据，请重新选择', duration: 3500})
            }
            this[`dialogVisible${this.type}`] = false
            this.dialogVisible3 = true
            info.count = order_num
            this.info = info
          })
        }
      })
    },
    handleSure() {
      this.fullLoading = true
      this.dialogVisible3 = false
      const data = { chg_type: this.type - 1, chg_teacher_to: this.formData.chg_teacher_to }
      if(this.type === 1) data.chg_teacher_from = this.formData.chg_teacher_from
      else if(this.type === 2) data.order_ids = this.selectionData.join(',')
      edit_belong_teacher(data).then(res => {
        this.$message({type: 'success', message:'处理成功', duration: 3500})
        this.isCanSelect = false
        this.selectionData = []
        this.getData()
      }).finally(() => {
        this.fullLoading = false
        this.handleInit()
      })
    },
    handleSelect() {
      this.isCanSelect = !this.isCanSelect
    },
    onSelectionChange(e) {
      this.selectionData = e.map(item => item.id)
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-date-editor .el-range-separator{
  padding: 0 !important;
  width: 0 !important;
}
.dialog-box {
  height: 120rem;
}
</style>
